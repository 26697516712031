import React, { useState } from 'react'
import * as styles from './styles/Navbar.module.css'
import { FiMenu } from '@react-icons/all-files/fi/FiMenu'
import { FiX } from '@react-icons/all-files/fi/FiX'
import { Link } from 'gatsby'
const links = [
  ['Home', '/'],
  ['About me', '/about'],
  ['Experience', '/experience'],
  // ['Events', '/events'],
  ['Blog', '/blog'],
  ['Resources', '/resources'],
  // ['Work with me', '/work-with-me'],
  ['Contact me', '/contact'],
]

//border-b border-gray-400
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuClassName = isMenuOpen ? 'block' : 'hidden'
  const MenuIcon = isMenuOpen ? FiX : FiMenu
  const toggleMenu = () => setIsMenuOpen((prevState) => !prevState)
  return (
    <nav className="fixed top-0 left-0 w-full z-40 bg-[#0E1117]">
      <div className="w-4/5 mx-auto lg:flex justify-between items-center py-4">
        <div className="flex lg:block justify-between">
          <Link to="/" className="font-secondary text-accent text-3xl">
            micorix
          </Link>
          <MenuIcon
            className="cursor-pointer lg:hidden text-2xl text-gray-400"
            onClick={toggleMenu}
          />
        </div>
        <ul
          className={`w-full lg:flex justify-end font-primary text-gray-400 ${menuClassName}`}
        >
          {links.map(([label, link]) => (
            <li className={styles.navItem} data-content={label} key={link}>
              <Link to={link}>{label}</Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
