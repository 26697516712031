import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { PageContainer } from '../components/blocks'
import PageTitle from '../components/PageTitle'
import SEO from "../components/SEO";

const NotFoundPage = () => {
  return (
    <Layout>
      <PageContainer>
        <PageTitle title="Ooops... 404" />
        <p className="text-lg mt-10">
          The page you{"'"}re looking for doesn{"'"}t exist.
          <br />
          <br />
          <Link to="/" className="underline hover:text-white transition">
            Go to home page
          </Link>
        </p>
      </PageContainer>
    </Layout>
  )
}

export const Head = () => (
    <SEO title="Not found"  />
)

export default NotFoundPage
