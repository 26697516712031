import React, {FC, PropsWithChildren} from "react"
import useSiteMetadata from "../hooks/useSiteMetadata";

interface SEOProps {
    title?: string
    description?: string
    pathname?: string
}

const SEO: FC<PropsWithChildren<SEOProps>> = ({ title, description, pathname, children }) => {
    const {title: siteTitle, description: defaultDescription, siteUrl, twitterUsername} = useSiteMetadata()
    const seo = {
        title: title ? `${title} • ${siteTitle}` : siteTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername,
    }
    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={seo.title}/>
            <meta name="twitter:url" content={seo.url}/>
            <meta name="twitter:description" content={seo.description}/>
            <meta name="twitter:creator" content={seo.twitterUsername}/>
            {children}
        </>
    )
}

export default SEO;