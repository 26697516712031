import React, { FC } from 'react'

interface PageTitle {
  title: string
}

const PageTitle: FC<PageTitle> = ({ title }) => (
  <h1 className="text-7xl font-secondary text-gray-100">
    <span className="text-accent">{title.charAt(0)}</span>
    {title.slice(1)}
  </h1>
)

export default PageTitle
